<template>
  <v-divider :class="'mt-' + marginTop + ' mb-' + marginBottom"></v-divider>
</template>

<script>
export default {
  name: "Divider",
  props: {
    marginTop: {
      type: Number,
      default: 1
    },
    marginBottom: {
      type: Number,
      default: 3
    }
  }
}
</script>

<style scoped>

</style>